import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import TinyPhoto from "../components/TinyPhoto/TinyPhoto";
import config from "../../data/SiteConfig";
import KonmariTOC from "./konmari-toc";

class KonmariClothes extends Component {
  render() {
    const pageName = "Category — Clothes";

    return (
      <Layout>
        <div className="singlePage">
          <Helmet title={`${pageName} ♡ ${config.siteTitle}`} />
          <div>
            <h1>KonMari</h1>
            <KonmariTOC />

            <h1>{pageName}</h1>
            <ul>
              <li>Tops</li>
              <li>Bottoms (Skirts)</li>
              <li>Dresses</li>
              <li>Pyjamas</li>
              <li>Jackets &amp; Outerwear</li>
              <li>Intimates</li>
              <li>Legwear</li>
              <li>Athletic Wear</li>
              <li>Purses</li>
              <li>Shoes</li>
              <li>Belts / Scarves</li>
              <li>Jewelry (Earrings)</li>
              <li>Jewelry (Necklaces / Rings / Bracelets / etc.)</li>
              <li>Hair Accessories</li>
            </ul>

            <p>TBD... <img src="/emoji/hearts7.gif" alt="" /></p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default KonmariClothes;
